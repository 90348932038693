@font-face {
    font-family: "Avenir";
    src: url(./fonts/AvenirNext-Medium.woff) format('woff'), 
    url(./fonts/AvenirNext-Medium.ttf) format("truetype"), 
    url(./fonts/AvenirNext-Medium.eot) format('embedded-opentype');
}

@font-face {
    font-family: "Avenir-Regular";
    src: url(./fonts/avenir-next-regular.woff) format('woff'), 
    url(./fonts/avenir-next-regular.ttf) format("truetype"), 
    url(./fonts//avenir-next-regular.eot) format('embedded-opentype');
}

@font-face {
    font-family: "Avenir-DemiBold";
    src: url(./fonts/AvenirNext-DemiBold.woff) format('woff'), 
    url(./fonts/AvenirNext-DemiBold.ttf) format("truetype"), 
    url(./fonts/AvenirNext-DemiBold.eot) format('embedded-opentype');
}

body {
    margin: 0;
    font-family: "Avenir-Regular";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #191f28;
    color: #ffff;
}
*:active, *:focus, *:hover {
    border-style: none;
    text-decoration: none;
    border: 0;
    outline: none;
}
.RCMfileUpload{
  display: none;
}

.RCM_file_upload {
  border: 0.5px solid #fff;
  display: inline-block;
  padding: 3px 6px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.RCM_file_upload:hover {
  background-color: #2a3641;
}

.RCM_Toast {
  background-color: #242a32;
  height: 100%;
  min-height: 100%;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
}

.RCM_Toast_heading {
    color: #fff;
    letter-spacing: .1667rem;
    font-size: .75rem;
}


.RCM_Navbar_dropdown{
    width: 15rem;
}


/* Start two level table */

    /* .RCM_two_level_table1 tbody{
        display:block;
        overflow:auto;
        height:70vh;
        width:99%;
    }

    .RCM_two_level_table1 thead tr{
        display: block;
    } */

  .RCM_two_level_table1 thead tr th{
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
      background-color: #2a3641;
      border: none;
      vertical-align: middle;
      padding: 0.85rem 0.5rem;
  }

  .RCM_two_level_table1 tbody tr {
      height: 2.188rem;
      cursor: pointer;
      border-bottom: 1px solid #242a32;
  }

  .RCM_two_level_table1 tbody tr td {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.5px;
      color: #fff;
      border: none;
      vertical-align: middle;
      padding: 0.5rem;
  }

  .RCM_two_level_table1 tbody tr:nth-child(odd) {
      background-color: rgba(125,133,140,0.34);
  }

  .RCM_two_level_table1 tbody tr:nth-child(even) {
      background-color: rgba(125,133,140,0.2);
  }



  .RCM_two_level_table1 .ant-table .ant-table-tbody > tr:hover > td {
    background: unset;
  }

  .RCM_Table_link{
      color: aqua;
  }

  .RCM_Table_link:hover{
    color: aqua;
  }


  .RCM_two_level_table1 .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
    position: relative;
    background: #242a32;
}

.RCM_two_level_table1 .ant-table-header {
    overflow: hidden;
    background: #2a3641;
}

.RCM_two_level_table1 .ant-table-placeholder{
    background: #2a3641;
    border: none;
}

.RCM_two_level_table1 .ant-empty-description{
    color: #fff;
}

.RCM_two_level_table1{
    margin-bottom: 1.5rem;
}

/* End Two level table */


/* Start Editing cell component css */

.react-bootstrap-table-editing-cell input, 
.react-bootstrap-table-editing-cell textarea{
  background: #2a3641 !important;
  border: none !important;
  color: #fff !important;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.5px;
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/* End editing cell component css */

/* Button CSS  */


.RCM_button_primary, .RCM_button_primary:hover, .RCM_button_primary:disabled {
    color: #fff;
    width: 17.5rem;
    height: 2.5rem;
    border-radius: 1.375rem;
    background-color: #16a5b0;
    font-size: 0.75rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-align: center;
    border: none;
}

.RCM_button_primary:focus {
    box-shadow: none;
}

.RCM_button_secondary, .RCM_button_secondary:hover, .RCM_button_secondary:disabled {
    width: 17.5rem;
    height: 2.5rem;
    border-radius: 1.375rem;
    background-color: #191f28;
    border: none;
    font-size: 0.75rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-align: center;
    color: #aab0b6;
    border: solid 0.0625rem #979797;
    font-family: 'Avenir-DemiBold';
}

.RCM_button_secondary:focus {
    box-shadow: none;
}

.RCM_reduce_btn_size {
    width: 10rem;
}

.RCM_reduce_btn_size:hover, .RCM_reduce_btn_size:disabled, .RCM_reduce_btn_size:active {
    width: 10rem;
}

/* Button CSS End  */

/* Start Modal Popup */

.modal-content {
    background-color: #191f28;
}

.RCM_CM_modal_content {
    background-color: #191f28;
    font-size: 14px;
}

.RCM_CM_modal_content:hover,
.RCM_CM_modal_content:active,
.RCM_CM_modal_content:focus {
    border: 1px solid rgba(0,0,0,.2);
}

.RCM_modal_heading {
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    color: #fff;
}



.RCM_CM_modal_content .modal-header {
    border: none;
}

.RCM_CM_modal_content .modal-body {
    border: none;
}

.RCM_CM_modal_content .modal-footer {
    border: none;
}

.RCM_CM_modal_content .close {
    font-size: 17px;
    font-weight: normal;
    color: #ffffff;
    opacity: 1;
}

.RCM_CM_modal_data {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
    margin-left: 4.5rem;
    margin-right: 4.5rem;
}

.RCM_CM_modal_class.modal {
    background-color: rgba(0, 0, 0, 0.8);
}

/* End Modal Popup*/

/* Controls CSS */

.RCM_form_control {
    font-family: "Avenir-Regular";
    background-color: #2a3641;
    width: 100%;
    border: 0;
    color: #ececec;
    font-size: 14px;
    width: 100%;
    height: 2.5rem;
}

input.RCM_form_control {
    padding-left: 0.75rem;
}

label.RCM_form_control, textarea.RCM_form_control {
    padding-top: 0.60rem;
    padding-left: 0.75rem;
    margin-bottom: 0;
}


/* Drop zone  */
.RCM_dropzone{
    background-color: #2a3641;
    color: #fff;
    font-size: 12px;
    min-height: 6rem;
}


/* Check box container */
.checkbox_container {
    display: block;
    position: relative;
    cursor: pointer;
    height: 1.2rem;
    width: 1.2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #191f28;
    border: 1px solid #acefff;
  }

/* Hide the browser's default checkbox */
.checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0.15rem;
    left: 0.15rem;
    height: 0.8rem;
    width: 0.8rem;
   
  }

/* On mouse-over, add a grey background color */
.checkbox_container:hover input ~ .checkmark {
    background-color: #2a3641;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox_container input:checked ~ .checkmark {
    background-color: #acefff;
  }

  .checkbox_container input:disabled ~ .checkmark {
      background-color: #86888a;
      cursor: not-allowed;
  }

/* Start Overlay Css */

  .RCM_CM_Overlay {
    z-index: 99;
    background-color: rgba(25,31,40, 0.4);
    position: fixed;
    width: 99%;
    height: 100%;
    padding: 0;
    top: 0;
    text-align: center;
}

.RCM_CM_Overlay_body {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.RCM_CM_Overlay_spinner {
    -webkit-appearance: none;
    background-image: url(../src/images/spinner.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 6rem;
    width: 6rem;
}

/* End Overlay Css */

/* Table filter text box  */

.filter.text-filter,
.filter.text-filter:hover,
.filter.text-filter:active,
.filter.text-filter:focus {
    background-color: #2a3641;
    color: white;
    border-radius: 0;
    border: 1px solid #ced4da;
    outline: none;
    box-shadow: none;
    padding-left: 0.75rem;
}

.RCM_Header_filter{
    margin-top: 0.5rem;
}

.RCM_Table_Header_Text{
    height: 3rem;
}

/* End Table filter text box */

/* RCM LIst */

.RCM_list{
    list-style: none;
    padding-left: 0;
    margin-top: 1rem;
}

.RCM_list_item{
    background-color: rgba(47,79,79,0.1);
    padding: 1rem;
    box-shadow: 0.2rem 0.2rem 0.8rem rgba(0,0,0,.4);
    margin-bottom: 1rem;
}

.RCM_list_content{
    padding-top:0.3rem;
}


.RCM_Document_Process_btn{
    margin-right: 0.5rem;
}

/*End RCM List */

    /*Scroll bar start*/
    ::-webkit-scrollbar {
        width: 0.6rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        width: 0.6rem;
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        opacity: 0.3;
        height: 5rem;
        border-radius: 5.5px;
        background-color: rgba(216,216,216,0.3);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(216,216,216,0.3);
    }

/*Scroll bar end*/

/* File upload */

.RCM_File_Drop .ant-upload.ant-upload-drag{
    background: unset;
    color: #fff;
}

.RCM_File_Drop .ant-upload.ant-upload-drag p.ant-upload-text,
.RCM_File_Drop .ant-upload.ant-upload-drag p.ant-upload-hint
{
    color: #fff;
}

.RCM_File_Drop .ant-upload-list,
.RCM_File_Drop .anticon,
.RCM_File_Drop .anticon.anticon-close
{
    color: #fff;
}


/* Select box*/


.RCM_select_box.RCM_mm_select_box.dropdown .btn-secondary {
    margin-left: 0;
    width: 100%;
    border: 0;
    border-radius: 0;
    background-color: #2a3641;
    color: #ececec;
    text-align: left;
    height: 2.5rem;
    font-size: 14px;
}

    .RCM_select_box.RCM_mm_select_box.dropdown .btn-secondary:focus {
        box-shadow: 0 0 0 0 !important;
    }

.RCM_select_box.RCM_mm_select_box.dropdown .dropdown-item {
    border-top: 1px solid #4e545a;
    border-radius: 0;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 14px;
    white-space: pre-wrap;
    color: #ececec;
}

.RCM_select_box.RCM_mm_select_box.dropdown .dropdown-item:focus,
.RCM_select_box.RCM_mm_select_box.dropdown .dropdown-item:hover,
.RCM_select_box.RCM_mm_select_box.dropdown .dropdown-item:active {
    border-top: 1px solid #4e545a !important;
    background-color: #2a3641 !important;
    border-radius: 0;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    white-space: pre-wrap;
    color: #ececec;
}

.dropdown-toggle.btn.btn-secondary {
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
}

.dropdown-toggle::after {
    height: 9px;
    width: 15px;
    background-image: url(./images/arrow-down.svg);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: transparent;
    display: inline-block;
    margin-left: .255em;
    margin-top: .310em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    float: right;
}


.RCM_select_box.RCM_mm_select_box.dropdown .dropdown-menu {
    margin-top: 0.5rem;
    background-color: #2a3641;
    border-radius: 0;
    width: 100%;
}

.RCM_NONDD_SELECT{
    background: unset;
    color: white;
    border: none;
    width: 100%;
}

.RCM_NONDD_SELECT:focus{
    outline: unset;
    outline-color: unset;
}

.RCM_NONDD_SELECT_OPTION{
    margin: 2px;
    padding: 8px;
    border-bottom: 1px solid #2a3641;
}

.RCM_NONDD_SELECT > option:hover{
    box-shadow: 0 0 10px 100px #4e545a inset;
    transition: all .1s ease-in-out;
}


.RCM_Error_Coffee_Icon svg{
    font-size: 96px;
}

.RCM_Error_Container{
    color: #fff;
    margin-top: 30vh;
}

.RCM_Error_Container h1{
    color: #fff;
}


.RCM_Table_delete_icon {
    cursor: pointer;
    width: 1.3rem;
    height: 1.3rem;
    background-color: var(--color-white);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url('../src/images/delete.svg');
}

.RCM_Table_document_icon {
    cursor: pointer;
    width: 1.3rem;
    height: 1.6rem;
    background-color: var(--color-white);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url('../src/images/document.svg');
}

.RCM_Edit_icon {
    cursor: pointer;
    width: 1.3rem;
    height: 1.3rem;
    background-color: var(--color-white);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url('../src/images/Edit.svg');
    display: inline-block;
    margin-left: 0.3rem;
}

.RCM_nopadding{
    padding: 0;
    padding-bottom: 0.3rem;
}
.RCM_upload {
    background-image: url('../src/images/download.svg');
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.RCM_download {
    background-image: url('../src/images/download.svg');
}
.RCM_menu_img {
    width: 1.45rem;
    height: 1.25rem;
    background-color: none;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    margin-right: .6rem;
}

.RCM_Error {
    color: red;
    font:bold;
}

.RCM_userMenu_Refresh {
    background-image: url('../src/images/refresh.svg');
    margin-top: 0.6rem;
    cursor: pointer;
}


.RCM_details_heading {
    padding-top: 1.875rem;
    padding-right: 2.188rem;
    padding-left: 2rem;
    cursor: auto;
}
/*start Home tabs*/
.RCM_NavTab_container {
    border: 0;
    padding-top: 1.7rem;
    padding-bottom: 0.6rem;
    display: table;
    margin: 0 auto;
    text-align: center;
    place-items: center;
}


    .RCM_NavTab_container .nav-tabs {
        border: 0;
    }

    .RCM_NavTab_container .nav-item {
        border: 0;
        margin-right: 2rem;
        margin-bottom: 0;
    }



    .RCM_NavTab_container .nav-link {
        font-family: "Avenir-DemiBold";
        color: #ececec;
        font-size: 0.75rem;
        letter-spacing: 0.1667rem;
        border: 0;
    }

        .RCM_NavTab_container .nav-link:hover {
            font-family: "Avenir-DemiBold";
            border: 0;
            font-size: 0.75rem;
            letter-spacing: 0.1667rem;
            text-decoration: none;
            color: #16a5b0;
        }

        .RCM_NavTab_container .nav-link.linkactive {
            font-family: "Avenir-DemiBold";
            border: 0;
            background-color: #191f28;
            border-bottom: 0.125rem solid #16a5b0;
            color: #16a5b0;
        }
.RCM_NavTab_container_details_no_padding {
    padding-top: 0rem;
}
        /*End*/
/*react datepicker css start*/
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    margin-left: -0.5rem;
    position: absolute;
}

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        box-sizing: content-box;
        position: absolute;
        border: 0.5rem solid transparent;
        height: 0;
        width: 1px;
    }

        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
        .react-datepicker__month-read-view--down-arrow::before,
        .react-datepicker__month-year-read-view--down-arrow::before {
            content: "";
            z-index: -1;
            border-width: 0.5rem;
            left: -8px;
            border-bottom-color: #aeaeae;
        }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-top: none;
        border-bottom-color: #f0f0f0;
    }

        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
            top: -1px;
            border-bottom-color: #aeaeae;
        }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}

    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-bottom: none;
        border-top-color: #fff;
    }

        .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
        .react-datepicker__month-read-view--down-arrow::before,
        .react-datepicker__month-year-read-view--down-arrow::before {
            bottom: -1px;
            border-top-color: #aeaeae;
        }

.react-datepicker-wrapper {
    display: inline-block;
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 9;
}

    .react-datepicker-popper[data-placement^="bottom"] {
        margin-top: 0.625rem;
    }

    .react-datepicker-popper[data-placement^="top"] {
        margin-bottom: 0.625rem;
    }

    .react-datepicker-popper[data-placement^="right"] {
        margin-left: 8px;
    }

        .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
            left: auto;
            right: 42px;
        }

    .react-datepicker-popper[data-placement^="left"] {
        margin-right: 8px;
    }

        .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
            left: 42px;
            right: auto;
        }

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 0.625rem;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 0.625rem;
    width: 0.625rem;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__navigation--previous {
    left: 0.625rem;
    border-right-color: #ccc;
}

    .react-datepicker__navigation--previous:hover {
        border: 0.45rem solid transparent;
        border-right-color: #b3b3b3;
    }

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--next {
    right: 0.625rem;
    border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
}

.react-datepicker__navigation--next:hover {
    border: 0.45rem solid transparent;
    border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
}

    .react-datepicker__navigation--years-previous:hover {
        border-top-color: #b3b3b3;
    }

.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
}

    .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #b3b3b3;
    }

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
}

    .react-datepicker__month .react-datepicker__month-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 0.3125rem 0 0.625rem 0.9375rem;
    text-align: left;
}

    .react-datepicker__input-time-container .react-datepicker-time__caption {
        display: inline-block;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container {
        display: inline-block;
    }

        .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
            display: inline-block;
            margin-left: 0.625rem;
        }

            .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
                width: 85px;
            }

                .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
                .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
                    -moz-appearance: textfield;
                }

        .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
            margin-left: 5px;
            display: inline-block;
        }

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 70px;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
}

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 70px;
        overflow-x: hidden;
        margin: 0 auto;
        text-align: center;
    }

        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
            list-style: none;
            margin: 0;
            height: calc(195px + (1.7rem / 2));
            overflow-y: scroll;
            padding-right: 0px;
            padding-left: 0px;
            width: 100%;
            box-sizing: content-box;
        }

            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
                height: 30px;
                padding: 5px 0.625rem;
            }

                .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
                    cursor: pointer;
                    background-color: #f0f0f0;
                }

            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
                background-color: #216ba5;
                color: white;
                font-weight: bold;
            }

                .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
                    background-color: #216ba5;
                }

            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
                color: #ccc;
            }

                .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
                    cursor: default;
                    background-color: transparent;
                }

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

    .react-datepicker__week-number.react-datepicker__week-number--clickable {
        cursor: pointer;
    }

        .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
            border-radius: 0.3rem;
            background-color: #f0f0f0;
        }

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

    .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
        background-color: #1d5d90;
    }

.react-datepicker__month--disabled {
    color: #ccc;
    pointer-events: none;
}

    .react-datepicker__month--disabled:hover {
        cursor: default;
        background-color: transparent;
    }

.react-datepicker__day,
.react-datepicker__month-text {
    cursor: pointer;
}

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover {
        border-radius: 0.3rem;
        background-color: #f0f0f0;
    }

.react-datepicker__day--today,
.react-datepicker__month-text--today {
    font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}

    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover {
        background-color: #32be3f;
    }

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1 {
    color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2 {
    color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover {
        background-color: #1d5d90;
    }

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff;
}

    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover {
        background-color: #1d5d90;
    }

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range {
    background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range {
    background-color: #f0f0f0;
    color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
    cursor: default;
    color: #ccc;
}

    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover {
        background-color: transparent;
    }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
    background-color: #216ba5;
}

.react-datepicker__month-text:hover {
    background-color: #f0f0f0;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
}

    .react-datepicker__year-read-view:hover,
    .react-datepicker__month-read-view:hover,
    .react-datepicker__month-year-read-view:hover {
        cursor: pointer;
    }

        .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
            border-top-color: #b3b3b3;
        }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 1.25rem;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}

    .react-datepicker__year-dropdown:hover,
    .react-datepicker__month-dropdown:hover,
    .react-datepicker__month-year-dropdown:hover {
        cursor: pointer;
    }

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 1.25rem;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

    .react-datepicker__year-option:first-of-type,
    .react-datepicker__month-option:first-of-type,
    .react-datepicker__month-year-option:first-of-type {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:last-of-type,
    .react-datepicker__month-option:last-of-type,
    .react-datepicker__month-year-option:last-of-type {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover,
    .react-datepicker__month-year-option:hover {
        background-color: #ccc;
    }

        .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
        .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
        .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
            border-bottom-color: #b3b3b3;
        }

        .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
        .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
        .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
            border-top-color: #b3b3b3;
        }

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;
}

    .react-datepicker__close-icon::after {
        background-color: #216ba5;
        border-radius: 50%;
        bottom: 0;
        box-sizing: border-box;
        color: #fff;
        content: "\00d7";
        cursor: pointer;
        font-size: 0.75rem;
        height: 16px;
        width: 16px;
        line-height: 1;
        margin: -8px auto 0;
        padding: 2px;
        position: absolute;
        right: 0px;
        text-align: center;
    }

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}

    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;
    }

@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
}

    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
        border: 0.45rem solid transparent;
        border-right-color: #b3b3b3;
    }

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
}

    .react-datepicker__portal .react-datepicker__navigation--next:hover {
        border: 0.45rem solid transparent;
        border-left-color: #b3b3b3;
    }

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.RCM_DatePicker {
    color: black
}
/*End*/
.RCM_AccountSummary_Datepicker {
    font-size: 0.9375rem;
    background-image: url(../src/images/calendar.svg);
    background-color: #191f28;
    color: white;
    border: none;
    border-bottom: 0.0625rem solid;
    background-repeat: no-repeat;
    padding-left: 2rem;
    display: block;
    width: 100%;
}

.RCM_Search {
    background-image: url(../src/images/search.svg);
    height: 1.851rem;
    width: 2rem;
    background-repeat: no-repeat;
    margin-bottom: -1.25rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 47%;
    margin-top: 1rem;
}
.RCM_Padding_Bottom{
    padding:0;
    padding-bottom:1rem;
}
.RCM_Account_Summary_heading {
    padding-left: 0.5rem;
    cursor: auto;
}

/* Errors and containers */

.RCM_validation_error_container {
    margin-top: 0.6rem;
    background-color: transparent;
    padding: 0.625rem;
}

.RCM_validation_error_text {
    color: #B71C1C;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.167rem;
    display: flex;
}

    .RCM_validation_error_text .RCM_error_img {
        height: 1.538rem;
        width: 1.538rem;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../src/images/error.svg');
        margin-right: 0.3rem;
    }

.RCM_msg_text {
    color: #f85e5e;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 0.6875rem;
    letter-spacing: 0.167rem;
    padding-left: 0.3rem;
    padding-top: 0.3rem;
}

.RCM_errorBoundryText {
    padding-left: 2rem;
    font-size: 0.75rem;
}
/* End error and containers*/
.RCM_common_table_column_text {
    text-align: left;
}

.RCM_common_table_column_number {
    text-align: right;
}

.RCM_clone_form_button{
display: flex;
align-items: flex-end;
}

.ant-message{
    z-index: 10000;
}

.ant-alert-success:hover {
    border: 1px solid #b7eb8f;
}

.ant-alert-error:hover {
    border: 1px solid #ffa39e;
}

.RCM_CM_form_label{
    font-family: "Avenir-Regular";
    color: #ececec;
    letter-spacing: .1rem;
}


/**/

#footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 3.7rem;
    padding: 0;
}

.RCM_footer_container {
    background-color: #242a32;
    height: 3.7rem;
    width: 100%;
    padding: 1rem 0 1rem 0;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding-left: 0.3125rem;
}
.RCM_footer_link_container {
    display: flex;
}
.RCM_footer_text_container {
    border-left: 0.0625rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 15%, #4e545a 16%, #4e545a 85%, transparent 86%, transparent 100%);
    border-image-slice: 1;
}

    .RCM_footer_text_container:first-child {
        border-left: 0;
    }

.RCM_footer_text, a.RCM_footer_text:hover {
    color: #ececec;
    height: 1rem;
    font-family: "Avenir-Regular";
    font-size: 0.75rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-left: 1.563rem;
    text-decoration: none;
}

.RCM_footer_text:last-child {
    margin-right: 1.563rem;
    margin-bottom: 2.5rem;
}

/**/


.RCM_Loading_Logo {
    position: absolute;
    top: 28%;
    left: 40%;
    font-size: 20rem;
    padding: 0;
}

.RCM_Loading_Logo_Spinner {
    font-size: 4rem;
    position: relative;
    top: -5rem;
}

.RCM_ExpandRow {
    background-color: #191f28;
}

.RCM_CM_form_row {
    margin-top: .675rem;
}

.RCM_delete_icon {
    cursor: pointer;
    width: 1.3rem;
    height: 1.3rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-image: url('./images/delete.svg');
}

.RCM_down_arrow {
    background: url(./images/arrow-down.svg) no-repeat;
    height: .6rem;
    width: 1rem;
    padding: 0;
    border: 0;
    background-size: auto 100%;
}

.RCM_right_arrow {
    background: url(./images/arrow-right.svg) no-repeat;
    height: 1rem;
    width: 1rem;
    padding: 0;
    border: 0;
    background-size: auto 100%;
}

.RCM_Sort_arrow_icon {
    height: 1rem;
    width: 1rem;
    background-size: 0.65rem;
    margin-left: 0.5rem;
}

.RCM_Sort_arrow_down_icon {
    background-image: url(./images/arrow-down.svg);
    background-repeat: no-repeat;
    background-size: 0.65rem;
    background-position: left center;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.RCM_Sort_arrow_up_icon {
    height: 1rem;
    width: 1rem;
    background-image: url(./images/arrow-up.svg);
    background-repeat: no-repeat;
    background-size: 0.65rem;
    background-position: left center;
    cursor: pointer;
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.RCM_Form_Error {
    background-color: #fce4e4;
    border: 1px solid #cc0033;
    color:#191f28;
    outline: none;
}

.RCM_Form_Error_Message {
    color: #cc0033;
}

.date-filter {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: '3px'
}

.date-filter-comparator,
.date-filter-comparator:focus,
.date-filter-comparator:hover,
.date-filter-comparator:active {
    background: transparent;
    background-color: #2a3641;
    color: white;
    border: 1px solid #ced4da;
    border-radius: 0;
    box-shadow: none;
    outline: none;
}

.date-filter-input,
.date-filter-input:hover,
.date-filter-input:focus,
.date-filter-input:active {
    background: transparent;
    background-color: #2a3641;
    color: white;
    border: 1px solid #ced4da;
    border-radius: 0;
    box-shadow: none;
    outline: none;

}